@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.loginNav{
  position: absolute;
  top: 1%;
  left: 2%;
}


.dash-card {
  color: white;
  border-radius: 3px;
  padding: 20px 0px;
}

.dash-card img {
  height: 90px;
  filter: invert(100%);
}

.Cards {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-row-gap: 15px;
  grid-column-gap: 20px;
  margin: auto;
  width: 96%;
}

.TableList {
  position: relative;
  background: white;
  width: 96%;
  margin: auto;
}

.TableList .table-responsive {
  position: absolute;
  width: 100%;
  background: white;
}


.table-responsive thead {
  position: sticky;
  top: 0;
  background: #faf8f8;
}


.TableList .table {
  overflow-x: scroll;
  background: white;
}

.TableList th {
  white-space: nowrap;
  line-height: normal;
  font-size: 14px;
  padding: 5px 20px 5px 15px;
  border-right: 0.99px solid gainsboro;
}

.TableList td {
  vertical-align: middle;
  white-space: nowrap;
  font-size: 15px;
  border-right: 0.99px solid gainsboro;
  text-align: center;
}

.TableList .table-responsive {
  border-top: 1px solid gainsboro;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0% !important;
}

.thumbnail {
  width: 95%;
  height: auto;
  border-radius: 3px;
}

.thumbnail:hover {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.text-center {
  text-align: center;
}

.PopUp-bg {
  position: fixed;
  top: 0%;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.PopUp {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 98vh;
  margin: auto;
  margin-top: 1vh;
}

.PopUp .img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  height: 100%;
  width: 30%;
}

.close {
  position: absolute;
  top: 3%;
  right: 30%;
  z-index: 200000;
  cursor: pointer;
}

.userImg {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.fs-7 {
  font-size: 1.1rem;
}

hr {
  margin: 0.5rem 0 !important;
}

.Modal-bg {
  position: fixed;
  display: flex;
  top: 0%;
  width: 100%;
  left: 0%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgb(243 240 240 / 70%);
  z-index: 20000000;
}

.modal-header {
  border-bottom: 1px solid gainsboro;
}

.Modal-content {
  width: 70%;
  background: white;
  height: fit-content;
  padding: 20px;
  border-radius: 10px;
}

.PhoneInput input {
  border: none;
  outline: none;
}

.blue {
  background: #00c0ef;
}

.green {
  background-color: #00a65a;
}

.orange {
  background: #f39c12;
}

.darkblue{
  background: #003865;
}

.darkorange{
  background: #EF5B0C;
}

@media (max-width: 750px) {
  .login-form {
    width: 75% !important;
  }
}

@media (max-width: 550px) {
  .userContainer {
    width: 95% !important;
  }
  .login-form {
    width: 92% !important;
  }
  .Cards,
  .TableList {
    width: 92%;
    margin-top: 20px !important;
  }
  .dash-card {
    padding: 5px 0px;
  }
  .navbar .container-fluid{
    justify-content: center !important;
    }
}
